import {
  Box,
  Button,
  Drawer,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Download from "./Download";
import { useDispatch, useSelector } from "react-redux";
import SearchBoard from "../../components/Filters/SearchBoard";
import GlobalSearch from "../../../../components/Search";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import User from "../../Grouping/components/User";
import Boards from "../../components/Filters/Boards";
import TeamFilter from "../../Grouping/components/TeamFilter";
import SelectDatePicker from "../../components/Filters/SelectDatePicker";
import { useSearchParams } from "react-router-dom";
import { addAllBoard, setDateRange } from "../../../../redux/SearchSlice";
import { setAllGroupUser } from "../../../../redux/TaskGroupingSlice";
import { useTeams } from "../../../../utils/reactQuery/teams";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const [searchParams, setSearchParams] = useSearchParams();
  const totalHours = useSelector((state) => state.search.totalWorkHours);
  const dispatch = useDispatch();
  const isMobile = useResponsive("down", "md");
  const trigger = useScrollTrigger();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const Oldboard = searchParams.get("board");
  const Olddate = searchParams.get("date");
  const { teams } = useTeams();

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };

  const clearFilters = () => {
    dispatch(addAllBoard([]));
    dispatch(setAllGroupUser([]));
    dispatch(setDateRange(null));
    // removeSearchParam("date");
    // removeSearchParam("board");
    removeSearchParams(["date", "board"]);
  };

  return (
    <>
      {isMobile ? (
        <div
          className="pos-sticky mt-4 ml-4 mb-2"
          style={{ top: trigger ? "0px" : "50px" }}>
          <div className="d-flex mb-2 mr-3 justify-content-space-between align-items-center ">
            <Typography fontWeight={600}>Worklogs</Typography>
            <div className="d-flex align-items-center">
              <p className="total-work mr-1">Total Work</p>
              <span className="hours-header">{totalHours} hours</span>
            </div>
          </div>

          <div className="d-flex justify-content-space-between">
            <Download />
            <img
              src={filterIcon}
              alt="filter"
              style={{ marginRight: "8px" }}
              onClick={() => setFilterDrawer(true)}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-space-between mb-4">
          <div className="d-flex">
            <p className="heading-worklog-tab mr-32">Worklogs</p>
            <div className="d-flex">
              <div className="mr-32">
                <p className="total-work">Total Work</p>
                <span className="hours-header">{totalHours} hours</span>
              </div>
              <Download />
            </div>
          </div>
          <GlobalSearch placeholder={"Search..."} />
        </div>
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <Stack justifyContent={"end"} alignItems={"end"}>
                {(Oldboard || groupingUser.length > 0 || Olddate) && (
                  <Button
                    size="small"
                    endIcon={<ClearIcon />}
                    className="mr-2 capitalize"
                    onClick={clearFilters}>
                    Clear Filter
                  </Button>
                )}
              </Stack>

              <div className="mt-2">
                <p className="filter-text">Select Date:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <SelectDatePicker />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Users:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <User user={groupingUser.map((item) => item?.users).flat()} />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Board:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <Boards />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Team:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  {teams && <TeamFilter team={teams} />}
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default Header;
