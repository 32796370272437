import { useQuery } from "@tanstack/react-query";
import { getAssets } from "../../sevices/apiDomainAssets";

export function useAssets() {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: ["logo"],
    queryFn: getAssets,
  });
  const icon = data?.data.data.responseData.fav_icon;
  const logo = data?.data.data.responseData.tenant_logo;
  return { isLoading, error, icon, isError, logo };
}
