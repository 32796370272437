import { PieChart, Pie, Cell, Tooltip } from "recharts";

export default function PieChartComp({ data, totalHours }) {
  const COLORS = [
    "#8979FF",
    "#6FD195",
    "#537FF1",
    "#FFAE4C",
    "#3CC3DF",
    "#FF928A",
  ];
  const labelPositions = [];

  const capitalize = (str) =>
    str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;

    // Base coordinates for the slice
    const sliceX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
    const sliceY = cy + outerRadius * Math.sin(-midAngle * RADIAN);

    // Intermediate point for the bend
    const bendX = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN);
    const bendY = cy + (outerRadius + 20) * Math.sin(-midAngle * RADIAN);

    // Final position for the label
    const labelX = bendX > cx ? bendX + 50 : bendX - 50; // Right or left based on position
    let labelY = bendY;

    // Prevent overlap by adjusting the labelY dynamically
    if (labelPositions.length > 0) {
      const lastY = labelPositions[labelPositions.length - 1];
      if (Math.abs(labelY - lastY) < 20) {
        labelY = lastY + 20; // Add spacing if overlapping
      }
    }
    labelPositions.push(labelY);

    const labelText = capitalize(`${data[index].name} ${data[index].value}`);

    return (
      <>
        {/* Line from slice to bend point */}
        <line
          x1={sliceX}
          y1={sliceY}
          x2={bendX}
          y2={bendY}
          stroke={COLORS[index % COLORS.length]}
          strokeWidth={1}
        />
        {/* Line from bend point to label */}
        <line
          x1={bendX}
          y1={bendY}
          x2={labelX}
          y2={labelY}
          stroke={COLORS[index % COLORS.length]}
          strokeWidth={1}
        />
        {/* Label */}
        <text
          x={labelX}
          y={labelY}
          fill="black"
          textAnchor={labelX > cx ? "start" : "end"}
          dominantBaseline="central"
          fontSize={12}>
          {labelText}
        </text>
      </>
    );
  };

  return (
    <PieChart width={700} height={400}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        label={renderCustomLabel}
        outerRadius={120}
        innerRadius={60}
        fill="#8884d8"
        dataKey="value">
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      {/* Center text */}
      <text
        x="50%"
        y="45%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={16}
        fontWeight="bold"
        fill="#333">
        TOTAL
      </text>
      <text
        x="50%"
        y="53%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={18}
        fontWeight="bold"
        fill="#333">
        {totalHours}
      </text>
      <Tooltip />
    </PieChart>
  );
}
