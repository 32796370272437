import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AddWorklogCustomizedDialogs from "../../worklog/addWorklog/addWorklogDialog";
import CustomizedProgressBars from "./progressbar";
import Avatar from "../../../../../components/Avatar";
import { useCard } from "../../../../../utils/reactQuery/cards";
import { currentUserId, isAdmin } from "../../../../../utils/constants";
import "./sidebar.scss";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  AddToWatchList,
  RemoveToWatchList,
  updateCard,
  assignCard,
} from "../../../../../sevices/apiCards";
import ComboBox from "./components/multiselectSearch";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import { differenceInDays, hasImage } from "../../../../../utils/helpers";
import DatePickerSmall from "./components/datePicker";
import { useBoardName } from "../../../../../utils/reactQuery/boards";
import AssignCard from "../../Kanban/AssignCard";
import CardLastSeenDetail from "./cardLastSeenDetail/CardLastSeenDetail";
import { useSelector } from "react-redux";
import Epic from "./components/epic/epic";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../../hooks/useResponsive";
import criticalIcon from "../../../../../styles/svg/critical_priority.svg";
import highIcon from "../../../../../styles/svg/high_priority.svg";
import mediumIcon from "../../../../../styles/svg/medium_priority.svg";
import lowIcon from "../../../../../styles/svg/low_priority.svg";

export default function SidebarGrid({ cardId, cardsOnBoard }) {
  const [addWorklog, setAddWorklog] = useState(false);
  const [isAllocatedHours, setAllocatedHours] = useState(false);
  const [inputAllocatedHours, setInputAllocatedHours] = useState(null);
  const [label, setLabel] = useState([]);
  const [showLebel, setShowLebel] = useState(false);
  const [showEpic, setShowEpic] = useState(false);
  const [depends, setDepends] = useState(false);
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [dueDate, setDueDate] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "md");
  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);
  useEffect(() => {
    setLabel(
      data?.labels.map((item) => ({
        label: item.label,
        label_id: item.label_id,
      }))
    );
    setDate(data?.due_date);
    setData(card?.data?.data?.responseData);
    return () => {};
  }, [card, data]);
  const user = useSelector((state) => state.users.logInUser);
  const firstName = user.first_name[0];
  const lastName = user.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const tooltipName = `${user.first_name || ""} ${user.last_name || ""}`;
  const assigneeFirstName = data?.assigned_to_user?.first_name[0];
  const assigneeLastImage = data?.assigned_to_user?.last_name[0];
  const assigneeName = `${assigneeFirstName || ""}${assigneeLastImage || ""}`;
  const assigneetooltipName = `${data?.assigned_to_user?.first_name || ""} ${
    data?.assigned_to_user?.last_name || ""
  }`;
  const reportertooltipName = `${data?.reported_by?.first_name || ""} ${
    data?.reported_by?.last_name || ""
  }`;
  const reporterHeadShotName = `${data?.reported_by?.first_name[0] || ""}${
    data?.reported_by?.last_name[0] || ""
  }`;

  const { boardName } = useBoardName(id);
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id;

  const conditionCheck =
    isAdmin() ||
    boardOwnerId === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const { mutate: updateCardmutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, data?.board_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "mineCards", // Match only queries that start with 'user'
      });
      toast.success(res.data.message);
      setDueDate(false);
      setShowEpic(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
    },
  });

  const { mutate: assignCardMutate } = useMutation({
    mutationFn: assignCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, data?.board_id],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: ["milestone_history", milestone],
      });
      queryClient.invalidateQueries({
        queryKey: ["epic_id", milestone],
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "mineCards", // Match only queries that start with 'user'
      });
      toast.success(res.data.message);
      setDueDate(false);
      setShowEpic(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
    },
  });

  const handleInputBlur = () => {
    if (data?.allocated_hours != inputAllocatedHours) {
      if (inputAllocatedHours != undefined) {
        updateCardmutate({
          card_id: cardId,
          data: {
            allocated_hours: inputAllocatedHours,
          },
        });
      }
    } else {
    }
    setAllocatedHours(false);
  };
  const handleLabelKeyDown = (e, newLabel) => {
    // console.log("VAL", newLabel, e);
    // let newLabelArray = label;
    // if (newLabel.trim() !== "") {
    //   newLabelArray = [newLabel];
    // } else if (newLabel == "") {
    //   // console.log('newLabel',typeof newLabel);

    //   newLabelArray = [];
    // }
    if (e.key === "Enter") {
      updateCardmutate({
        card_id: cardId,
        data: {
          labels: newLabel,
        },
      });
      setShowLebel(false);
    }
  };
  const handleDueDate = (e) => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setDueDate(true);
    }
  };
  const handleDuedateBlur = (event) => {
    setDueDate(event);
  };

  const handleShowEpic = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setShowEpic(true);
    }
  };

  const handleShowLebel = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.assigned_to_user_id == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      setShowLebel(true);
    }
  };

  const handleDependsClose = (parentCardId) => {
    console.log("parentCardId", parentCardId);
    updateCardmutate({
      card_id: cardId,
      data: {
        parent_card_id: parentCardId,
      },
    });
    setDepends(false);
  };

  const handleEpicBlur = (epic_id) => {
    setShowEpic(false);
    if (data?.epic?.epic_id != epic_id) {
      updateCardmutate({
        card_id: cardId,
        data: {
          epic_id: epic_id,
        },
      });
    }
  };

  const handleLabelBlur = (e, newLabel) => {
    if (newLabel != label) {
      updateCardmutate({
        card_id: cardId,
        data: {
          labels: newLabel,
        },
      });
      setShowLebel(false);
    } else {
      setShowLebel(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (data?.allocated_hours != inputAllocatedHours) {
        if (inputAllocatedHours != undefined) {
          e.preventDefault();
          updateCardmutate({
            card_id: cardId,
            data: {
              allocated_hours: inputAllocatedHours,
            },
          });
        }
      }

      setAllocatedHours(false);
    }
  };
  const handleInputChange = (event, newInputValue) => {};
  const handleAllocatedHoursInput = () => {
    if (
      isAdmin() ||
      data?.reporter == currentUserId() ||
      boardOwnerId == currentUserId()
    ) {
      setAllocatedHours(true);
    }
  };
  const handleAssignClick = () => {
    assignCardMutate({
      card_id: cardId,
      data: {
        assigned_to_user_id: currentUserId(),
      },
    });
  };
  const handleInputHourChange = (e) => {
    setInputAllocatedHours(e.target.value);
  };
  const handleDueDateKeyDown = (newDate) => {
    if (newDate) {
      updateCardmutate({
        card_id: cardId,
        data: {
          due_date: moment(newDate).format("YYYY-MM-DD"),
        },
      });
    } else {
      updateCardmutate({
        card_id: cardId,
        data: {
          due_date: 0,
        },
      });
    }
    setDueDate(false);
  };

  const handleAddWatchList = () => {
    // console.log("Watching", data?.board?.workflow_id);
    AddToWatchList(cardId)
      .then((res) => {
        console.log("res of watchlist == ", res);
        toast.success(res.data.message);
        queryClient.invalidateQueries({
          queryKey: [`card`, cardId],
        });
        queryClient.invalidateQueries({
          queryKey: [`watching`],
        });
      })
      .catch((err) => {
        console.log("err in watchlist", err);
        toast.error(err.response.data.message);
      });
  };

  const handleRemoveWatchList = () => {
    RemoveToWatchList(cardId)
      .then((res) => {
        console.log("res of watchlist == ", res);
        toast.success(res.data.message);
        queryClient.invalidateQueries({
          queryKey: [`card`, cardId],
        });
        queryClient.invalidateQueries({
          queryKey: [`watching`],
        });
      })
      .catch((err) => {
        console.log("err in watchlist", err);
        toast.error(err.response.data.message);
      });
  };

  const CustomRadio = styled(Radio)(({ theme }) => ({
    height: "18px", // Set the height
    width: "18px", // Set the width
    marginLeft: "6px",
    // "&.Mui-checked": {
    //   color: "#4caf50", // Active color
    // },
    // "&.MuiRadio-root": {
    //   color: "#ccc", // Inactive color
    // },
    "& svg": {
      height: "16px", // Ensure the SVG inside the radio scales
      width: "16px", // Ensure the SVG inside the radio scales
    },
  }));
  const handlePriority = (value) => {
    updateCardmutate({
      card_id: cardId,
      data: {
        priority: value.target.value,
      },
    });
  };
  return (
    <>
      {addWorklog && (
        <AddWorklogCustomizedDialogs
          cardId={cardId}
          addWorklog={addWorklog}
          setAddWorklog={setAddWorklog}
          heading={"Add Work Log"}
          buttonName={"Save"}
        />
      )}
      <Box>
        <Grid container className="sidebar-block">
          <Grid
            item
            className={isMobile ? "mobile-sidebar-heading" : "sidebar-heading"}
          >
            <div className="sidebar-heading-content">
              {/* <div className="add-parent">
                <img src={edit} alt="" />
                <span className="add-parent-content">Add parent</span>
                <span style={{ opacity: "0.25" }}>/</span>
              </div> */}
              {/* <div className="note">
                <img src={note} alt="" />
                <p>{`${card?.data?.data?.responseData?.board?.board_name
                  .substring(0, 3)
                  .toUpperCase()} - ${data?.card_id}`}</p>
              </div> */}
              <CardLastSeenDetail cardDetail={data} />
            </div>
          </Grid>
          <Grid item className="sidebar-details">
            <Grid item className="sidebar-details-content">
              <Grid item className="sidebar-worklog">
                <div className="sidebar-worklog-detail">Details</div>
                <div className="sidebar-Addworklog-btn">
                  {data?.in_watchlist ? (
                    <Button onClick={handleRemoveWatchList}>
                      Remove from Watchlist
                    </Button>
                  ) : (
                    <Button onClick={handleAddWatchList}>
                      Add to Watchlist
                    </Button>
                  )}
                </div>
              </Grid>

              <div className="box">
                <Grid item className="priority_cont">
                  <p className="mr-3">Priority </p>
                  <FormControl className="d-flex">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={data?.priority || ""}
                      onChange={handlePriority}
                      name="row-radio-buttons-group"
                    >
                      {["Critical", "High", "Medium", "Low"].map(
                        (value, idx) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            sx={{ width: "110px" }}
                            control={
                              <CustomRadio
                                disabled={
                                  !(
                                    isAdmin() ||
                                    data?.reporter == currentUserId() ||
                                    boardOwnerId == currentUserId()
                                  )
                                }
                              />
                            }
                            label={
                              <Stack direction="row" alignItems={"center"}>
                                <img
                                  src={
                                    idx === 0
                                      ? criticalIcon
                                      : idx === 1
                                      ? highIcon
                                      : idx === 2
                                      ? mediumIcon
                                      : lowIcon
                                  }
                                  alt="icon"
                                />
                                <span style={{ fontSize: "14px" }}>
                                  {value}
                                </span>
                              </Stack>
                            }
                          />
                        )
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </div>
              {/* 
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Depends on</p>
                  <div className="taskdetail-sidebar-right-col">
                    {depends ? (
                      <DependsOnDropdown
                        cardsOnBoard={cardsOnBoard}
                        open={depends}
                        close={handleDependsClose}
                        onBlur={setDepends}
                      />
                    ) : (
                      <div className="due-date" onClick={handleDepends}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.parent_card_id ? (
                            data.parent_card_id
                          ) : (
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </Grid>
              </div> */}

              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Allocated Hours</p>
                  <div
                    className="taskdetail-sidebar-right-col"
                    onClick={handleAllocatedHoursInput}
                  >
                    {isAllocatedHours ? (
                      <TextField
                        id="outlined-basic-input"
                        fullWidth
                        className="taskdetail-sidebar-right-col"
                        // label={label}

                        size={"small"}
                        defaultValue={data?.allocated_hours}
                        variant="outlined"
                        onChange={handleInputHourChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleInputBlur}
                        autoFocus
                      />
                    ) : (
                      <div className="allocated-hours-input">
                        {data?.allocated_hours ? (
                          <p>{data?.allocated_hours}</p>
                        ) : (
                          <p className="due-date-input-allocate-hour-none">
                            None
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Time Tracking</p>
                  <div className="time-tracking-input taskdetail-sidebar-right-col">
                    <CustomizedProgressBars
                      allocatedHours={data?.allocated_hours}
                      hoursSpent={data?.total_hours_spent}
                    />
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Hours Spent</p>
                  <div className="taskdetail-sidebar-right-col">
                    <div className="allocated-hours-input">
                      <p>
                        {data?.total_hours_spent ? (
                          data?.total_hours_spent
                        ) : (
                          <p className="due-date-input-none">None</p>
                        )}
                      </p>
                    </div>
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Assignee</p>
                  <div className="assignee-detail taskdetail-sidebar-right-col">
                    <div className="assignee-image">
                      <AssignCard
                        tooltipName={
                          data?.assigned_to_user
                            ? assigneetooltipName
                            : tooltipName
                        }
                        project={data}
                        name={data?.assigned_to_user ? assigneeName : name}
                        accessForAssign={conditionCheck}
                        cardId={cardId}
                      />
                      {/* <Avatar
                    title={(data?.assigned_to_user) ? assigneetooltipName : tooltipName}
                      
                      className={" header-avatar add-comment-avtar"}
                      user_image={(data?.assigned_to_user) ? data?.assigned_to_user?.user_image : user.user_image}
                      style={(data?.assigned_to_user) ? ({
                        bgcolor: data?.assigned_to_user?.profile_color?.background,
                        color: data?.assigned_to_user?.profile_color?.text,
                      }) : ({
                        bgcolor: user?.profile_color?.background,
                        color: user?.profile_color?.text,
                      })}
                    >
                       {(data?.assigned_to_user) ? assigneeName : name}
                    </Avatar> */}
                    </div>
                    <div className="assignee">
                      <div className="assignee-name">
                        {/* {(data?.assigned_to_user) ? <p>{`${data?.assigned_to_user?.first_name} ${data?.assigned_to_user?.last_name}`}</p> : <p>{`${tooltipName}`}</p>} */}
                        {data?.assigned_to_user && (
                          <p>{`${data?.assigned_to_user?.first_name} ${data?.assigned_to_user?.last_name}`}</p>
                        )}
                      </div>
                      <div className="sidebar-assigntome-btn">
                        {data?.assigned_to_user ? null : (
                          <Button onClick={handleAssignClick} sx={{}}>
                            Assign to me
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
              <div className="box">
                <Grid item className="allocated-hours ">
                  {" "}
                  <p>Label</p>
                  {/* <div  > */}
                  <div className="taskdetail-sidebar-right-col">
                    {showLebel ? (
                      <ComboBox
                        onBlur={handleLabelBlur}
                        labels={label}
                        // setLebelCallback={handleLabelChange}
                        handleInputChange={handleInputChange}
                        onKeyDown={handleLabelKeyDown}
                        boardId={data?.board_id}
                        cardId={cardId}
                        setShowLebel={setShowLebel}
                      />
                    ) : (
                      <div className="due-date" onClick={handleShowLebel}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.labels.length !== 0 ? (
                            data?.labels.map((item) => item.label).join(", ")
                          ) : (
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  {" "}
                  <p>Milestone</p>
                  <div className="taskdetail-sidebar-right-col">
                    {showEpic ? (
                      // <ComboBox
                      //   onBlur={handleLabelBlur}
                      //   labels={label}
                      //   setLebelCallback={handleLabelChange}
                      //   handleInputChange={handleInputChange}
                      //   onKeyDown={handleEpicKeyDown}
                      // />
                      <Epic
                        handleEpicBlur={handleEpicBlur}
                        cardId={cardId}
                        showEpic={showEpic}
                        setShowEpic={setShowEpic}
                        boardId={data?.board_id}
                      />
                    ) : (
                      <div className="due-date" onClick={handleShowEpic}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.epic?.epic_name ? (
                            data?.epic?.epic_name
                          ) : (
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  {" "}
                  <p>Due Date</p>
                  {/* <div className='dueDate-box' onClick={handleDueDate} onBlur ={handleDuedateBlur}> */}
                  <div className="taskdetail-sidebar-right-col">
                    {dueDate ? (
                      // <div
                      //   className="taskdetail-sidebar-right-col"
                      //   // onKeyDown={(event) => handleDueDateKeyDown(event)}
                      // >
                      <DatePickerSmall
                        date={date}
                        handleDueDateKeyDown={handleDueDateKeyDown}
                        handleDuedateBlur={handleDuedateBlur}
                      />
                    ) : (
                      // </div>
                      <div className="due-date" onClick={handleDueDate}>
                        {" "}
                        <div className="due-date-input">
                          {" "}
                          {data?.due_date ? (
                            // moment(data?.due_date).format("MM-DD-YYYY")
                            moment
                              .utc(data?.due_date)
                              .local()
                              .format("MM/DD/YYYY")
                          ) : (
                            // <CustomDateFormatter date={data?.due_date} dateOnly={true}/>
                            <p className="due-date-input-none">None</p>
                          )}{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                  {/* <DatePickerSmall date={date}  handleDueDateKeyDown={handleDueDateKeyDown}  isValid={dueDate} /> */}
                  {/* {<DatePickerSmall date={date} setDate={setDate} handleDueDateKeyDown={handleDueDateKeyDown}/>} */}
                </Grid>
              </div>

              <div className="box">
                <Grid item className="allocated-hours ">
                  <p>Reporter</p>
                  <div className="reporter-detail taskdetail-sidebar-right-col ">
                    <div className="reporter-image">
                      <Avatar
                        title={reportertooltipName}
                        className={" header-avatar add-comment-avtar"}
                        user_image={data?.reported_by?.user_image}
                        style={
                          !hasImage(data?.reported_by?.user_image) && {
                            bgcolor:
                              data?.reported_by?.profile_color?.background,
                            color: data?.reported_by?.profile_color?.text,
                          }
                        }
                      >
                        {reporterHeadShotName}
                      </Avatar>
                    </div>
                    <div className="reporter-name">
                      {data?.reported_by ? (
                        <p>{`${data?.reported_by?.first_name} ${data?.reported_by?.last_name}`}</p>
                      ) : (
                        <p>None</p>
                      )}
                    </div>
                    <div></div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* <Grid conatiner className="created-date-box">
            <Grid item className='created-date'>
               Created {<CustomDateFormatter date={data?.date_created}/>}
               <Grid item className='updated-date'>
               Updated {differenceInDays(data?.updated_at) > 0 ? `${differenceInDays(data?.updated_at)} days ago` : 'Today'}
               </Grid>
            </Grid>
            </Grid> */}
          <div className="created-date-box">
            <div className="created-date">
              Created {<CustomDateFormatter date={data?.date_created} />}
              <div className="updated-date">
                Updated{" "}
                {differenceInDays(data?.updated_at) > 0
                  ? `${differenceInDays(data?.updated_at)} days ago`
                  : "Today"}{" "}
              </div>
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
}
