import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import Boards from "../Filters/Boards";
import Epics from "../Filters/Epics";
import SelectDatePicker from "../Filters/SelectDatePicker";
import Worklog from "./Worklog";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import { addAllBoard } from "../../../../redux/SearchSlice";
import { useSearchParams } from "react-router-dom";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import { useResponsive } from "../../../../hooks/useResponsive";

function Filter() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const Oldboard = searchParams.get("board");
  const reporter = searchParams.get("reporter");
  const [workflowId, setWorkflowId] = useCustomSearchParams("workflow_id");
  const [setMultipleSearchParams] = useSetMultipleParams();
  const isMobile = useResponsive("down", "md");
  // const [generic, setGeneric] = useCustomSearchParams("is_genric");
  const [genericId, setGenericId] = useState(false);
  // console.log("workflowId", workflowId);
  const { userWorkflows } = useUserWorkflowList();
  useEffect(() => {
    console.log(
      "workflows for user list == ",
      userWorkflows?.data?.data?.responseData[0]?.workflow_id
    );
    if (workflowId && workflowId != "undefined") {
      //eat five star do nothing
    } else {
      setWorkflowId(
        "workflow_id",
        userWorkflows?.data?.data?.responseData[0]?.workflow_id
      );
    }
  });
  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const checkedList = useSelector((state) => state.search.boardSearch);

  console.log("Oldboard", Oldboard, checkedList);
  const clearFilters = () => {
    // removeSearchParam('board')
    removeSearchParams(["board", "reporter"]);
    dispatch(addAllBoard([]));
  };

  const changeReporterSearch = (event) => {
    event.stopPropagation();
    // event.preventDefault();
    if (reporter == "true") {
      removeSearchParams(["reporter"]);
    } else {
      updateSearchParams("reporter", true);
    }
  };
  const checkGenric = (id) => {
    // console.log("ID", userWorkflows?.data?.data?.responseData);

    const temp = userWorkflows?.data?.data?.responseData.filter((ele) => {
      return id == ele.workflow_id;
    });
    if (temp) {
      const isGeneric = temp[0]?.is_generic;
      setGenericId(isGeneric);

      // Set both workflow_id and generic based on isGeneric
      setMultipleSearchParams([
        { name: "workflow_id", value: id },
        { name: "is_generic", value: isGeneric },
      ]);

      console.log("is generic == temp", temp);
    }
  };
  return (
    <div className="d-flex align-items-center">
      {isMobile ? (
        <div>
          <p className="filter-text">Select Workflow:</p>
          <Box
            className={"workflow_steps_cont"}
            sx={{ maxHeight: "167px", overflow: "auto" }}>
            {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
              return (
                <Typography
                  className={"workflow_steps"}
                  sx={{
                    backgroundColor: ele.workflow_id == workflowId && "#C5DCF3",
                  }}
                  onClick={() => checkGenric(ele.workflow_id)}>
                  {ele.workflow_name}
                </Typography>
              );
            })}
          </Box>

          <p className="filter-text" style={{ marginBottom: "5px" }}>
            Filter By:
          </p>
          <Boards />

          <Button
            className="filter-btn white-button capitalize"
            sx={{
              height: "38px",
              "&:hover": {
                outline: "none",
                backgroundColor: "rgb(240 249 254)",
                border: "1px solid #1b3e61",
              },
            }}
            variant="outlined">
            <label class="custom-checkbox">
              <input
                type="checkbox"
                checked={reporter == "true"}
                onClick={changeReporterSearch}
              />
              <span class="checkmark"></span>
              Include created by me
            </label>
          </Button>

          {checkedList && checkedList.length == 1 && <Epics />}
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center mr-2">
            <p className="filter-text">Select Workflow:</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={workflowId}
              sx={{ height: "38px", width: "150px", fontSize: "15px" }}
              onChange={(event) => {
                console.log("WORK", event.target.value);
                // setWorkflowId("workflow_id", event.target.value);
                checkGenric(event.target.value);
              }}>
              {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
                return (
                  <MenuItem value={ele.workflow_id}>
                    {ele.workflow_name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          {(Oldboard || reporter) && (
            <Button
              size="small"
              endIcon={<ClearIcon />}
              className="mr-2 capitalize"
              onClick={clearFilters}>
              Clear Filter
            </Button>
          )}
          <p className="filter-text">Filter By:</p>
          <Boards />
          {checkedList && checkedList.length == 1 && <Epics />}
          <Button
            className="filter-btn white-button capitalize"
            sx={{
              height: "38px",
              "&:hover": {
                outline: "none",
                backgroundColor: "rgb(240 249 254)",
                border: "1px solid #1b3e61",
              },
            }}
            variant="outlined">
            <label class="custom-checkbox">
              <input
                type="checkbox"
                checked={reporter == "true"}
                onClick={changeReporterSearch}
              />
              <span class="checkmark"></span>
              Include created by me
            </label>
          </Button>
        </>
      )}
    </div>
  );
}

export default Filter;
