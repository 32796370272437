import React from "react";
import { Box, Typography } from "@mui/material";

import email from "../../../styles/svg/email.svg";
import "./forgotPassword.scss";
import ForgotPasswordMessage from "../../../utils/Enums";

const SendLinkConfirmation = () => {
  return (
    <Box component="form" noValidate className="submitForm-box">
      <div className="d-flex justify-content-center mb-4">
        <img src={email} alt="email logo" />
      </div>

      <Typography
        className="text-center black-primary-color mb-4 weight-700"
        component={"h1"}
        variant="h5"
      >
        {ForgotPasswordMessage.LINK_SEND_SUCCESS}
      </Typography>
      <Typography
        className="text-center forgot-password-para mb-4"
        component={"h1"}
        variant="h5"
      >
        {ForgotPasswordMessage.LINK_SEND_SUCCESS_MESSAGE}
      </Typography>
    </Box>
  );
};

export default SendLinkConfirmation;
