import React from "react";
import { Stack } from "@mui/material";
import CustomizedProgressBars from "../../pages/board/components/progressBar/ProgressBar";
import CardId from "./CardId";
import ListAssignCard from "../../pages/board/components/listView/AssignCard";
import ChangeMilestone from "../../pages/board/components/listView/ChangeMilestone";
import ChangeStatus from "../CardComponents/ChangeStatus";
import CardTitleField from "../../pages/board/components/listView/CardTitleField";

export const dataGridColumn = [
  {
    field: "col1",
    headerName: "Card Id",
    minWidth: 124,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <CardId id={value?.value} />,
  },
  {
    field: "col2",
    headerName: "Card Title",
    minWidth: 270,
    flex: 1,
    sortable: false,
    resizable: false,
    hideable: true,
    hide: true,
    renderCell: (value) => (
      <CardTitleField
        cardId={value?.value?.card_id}
        value={value?.value?.title}
        isReadOnly={!value?.value?.isEditAllowed}
        height={"auto"}
      />
    ),
  },
  {
    field: "col3",
    headerName: "Card Status",
    minWidth: 182,
    // type: "boolean",
    headerAlign: "center",
    flex: 1,
    sortable: true,
    resizable: false,
    align: "center",
    renderCell: (value) =>
      value?.value?.card?.workflow_id && (
        <ChangeStatus
          cardId={value?.value?.card?.card_id}
          workflow_id={value?.value?.card?.workflow_id}
          boardId={value?.value?.card?.board_id}
          checkCondition={value?.value?.isEditAllowed}
          workflowStepId={value?.value?.card?.workflow_step_id}
        />
      ),
  },

  {
    field: "col5",
    headerName: "Assignee",
    minWidth: 236,
    flex: 1,
    sortable: true,
    resizable: false,
    renderCell: (value) => {
      const fullName = `${value?.value?.card?.assigned_to_user?.first_name} ${value?.value?.card?.assigned_to_user?.last_name}`;
      return (
        <ListAssignCard
          cardData={value?.value?.card}
          name={fullName}
          cardId={value?.value?.card?.card_id}
          tooltipName={fullName}
        />
      );
    },
  },
  {
    field: "col6",
    headerName: "Label",
    minWidth: 136,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <span style={{ color: "#FD7401" }}>{value?.value}</span>
    ),
  },
  {
    field: "col7",
    headerName: "Milestone",
    minWidth: 182,
    flex: 1,
    sortable: true,
    resizable: false,
    renderCell: (value) => <ChangeMilestone project={value?.value} />,
  },
  {
    field: "col8",
    headerName: "Alloted Hours",
    minWidth: 182,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <span>
        {value?.value} {value?.value ? "hours" : ""}
      </span>
    ),
  },
  {
    field: "col9",
    headerName: "Hours Spent",
    minWidth: 182,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <span>
        {value?.value}{" "}
        {value?.value ? (value?.value == 1 ? "hour" : "hours") : ""}
      </span>
    ),
  },
  {
    field: "col10",
    headerName: "Time Tracking",
    minWidth: 206,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => (
      <Stack sx={{ width: "100%" }}>
        <CustomizedProgressBars
          allocatedHours={value?.value?.allotedHours}
          hoursSpent={value?.value?.hoursSpent ? value?.value?.hoursSpent : "0"}
        />
      </Stack>
    ),
  },
  {
    field: "col11",
    headerName: "Due Date",
    minWidth: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value?.value}</span>,
  },
  {
    field: "col12",
    headerName: "Created Date",
    minWidth: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value?.value}</span>,
  },
  {
    field: "col13",
    headerName: "Reporter",
    minWidth: 160,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value?.value}</span>,
  },
];
