import React, { useState } from "react";
import MergeMilestonePopUp from "./MergeMilestonePopUp";

const MergeMilestone = ({ milestones }) => {
  const [openMergePopup, setOpenMergePopup] = useState(false);

  const unlockedMilestone = milestones?.filter(
    (item) => item.is_locked === false
  );

  const handleMergeMilestonePopup = () => {
    setOpenMergePopup(true);
  };

  return (
    <>
      {openMergePopup && (
        <MergeMilestonePopUp
          open={openMergePopup}
          close={setOpenMergePopup}
          unlockedMilestone={unlockedMilestone}
        />
      )}
      {/* <div className="create-epic-btn" >
        <Button
          sx={{
            backgroundColor: "#1B3E61",
            "&:hover": {
              backgroundColor: "#1B3E61",
              border: "none",
            },
          }}
          variant="contained"
        >
          {" "}
          <img src={addEpicIcone} /> Merge milestone
        </Button>
      </div> */}
      <p
        className="card-anchor merge-milestone-div"
        onClick={handleMergeMilestonePopup}
      >
        Merge Milestone
      </p>
    </>
  );
};

export default MergeMilestone;
