import React, { useState } from "react";
import { useUsers } from "../../../../utils/reactQuery/users";
import Avatar from "../../../../components/Avatar";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { createAvtarName } from "../../../../utils/helpers";
import { isAdmin } from "../../../../utils/constants";
import { AvatarGroup, Menu } from "@mui/material";
import { Avatar as Image } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMyTaskUser } from "../../../../redux/SearchSlice";
import SearchBoard from "../../components/Filters/SearchBoard";
import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useSearchParams } from "react-router-dom";
import GroupUser from "../../../../styles/svg/Team-group.svg";
import { AntSwitch } from "../../../../components/Switch/antSwitch";
import Users from "./Users";
import Team from "./Team";

function TeamUser({ user, team }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useResponsive("down", "md");
  const open = Boolean(anchorEl);
  const { users } = useUsers();
  const [teamView, setTeamView] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    setAnchorEl(null);
    setSearchQuery("");
  };

  const filteredItems = users
    ? searchQuery
      ? users.data.data.responseData.filter((item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : users.data.data.responseData
    : [];

  return (
    <div className="d-flex align-items-center ">
      <div>
        <div onClick={handleClick} className="cursor-pointer">
          <AvatarGroup max={4} className="avatar-group">
            {user.map((el) => (
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${el.first_name} ${el.last_name}`}
                user_image={el.user_image}
                title={`${el.first_name} ${el.last_name}`}
                className="user-avtar-dropdown user-avatar"
                style={{
                  bgcolor: el?.profile_color?.background,
                  color: el?.profile_color?.text,
                }}>
                {createAvtarName(`${el.first_name} ${el.last_name}` || "")}
              </Avatar>
            ))}
            {user.length < 1 && <Image />}
          </AvatarGroup>
        </div>

        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,

              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 14,
                height: 14,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          className="assign-board-owner-list">
          <div
            onKeyDown={(e) => e.stopPropagation()}
            className="worklog_parent d-flex align-items-center"
            style={{
              marginTop: teamView ? "16px" : "0px",
              marginBottom: teamView ? "16px" : "0px",
            }}>
            <div className="search_worklog_cont">
              {!teamView && (
                <SearchBoard
                  onSearch={(val) => setSearchQuery(val)}
                  searchQuery={searchQuery}
                />
              )}
            </div>
            <AntSwitch
              defaultChecked
              checked={teamView}
              onChange={(event) => setTeamView(event.target.checked)}
              inputProps={{ "aria-label": "ant design" }}
            />

            <img
              className="ml-1 group_icon"
              style={{ opacity: teamView ? "1" : "0.3" }}
              src={GroupUser}
              alt="team_view"
            />
          </div>
          {teamView ? (
            <Team team={team} user={user} filteredItems={filteredItems} />
          ) : (
            <Users
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              user={user}
              filteredItems={filteredItems}
            />
          )}
        </Menu>
      </div>

      {!isMobile && (
        <div>
          <p className="select-user">{user.length < 1 ? "Select User" : ""}</p>
        </div>
      )}
    </div>
  );
}

export default TeamUser;
