import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import EditEpic from "./EditEpic";
import { Button, Menu, MenuItem, Divider, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import apicIcon from "../../../../../styles/svg/updt_milestone.svg";
import CreateEpic from "./CreateEpic";
import "./epicbutton.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { useEpic } from "../../../../../utils/reactQuery/epics";
import { searchCard } from "../../../../../redux/EpicSearchSlice";
import { useBoardName } from "../../../../../utils/reactQuery/boards";
import EditMIlestone from "../../../../../components/Milestone/EdiMilestone";
import { isAdmin } from "../../../../../utils/constants";
import { useResponsive } from "../../../../../hooks/useResponsive";
import MergeMilestone from "../../mergeMilestone/MergeMilestone";

const EpicButton = ({ boardNameDetails, showNoMile = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [epic, setEpic] = useState(
    searchParams.get("milestone")
      ? searchParams
          .get("milestone")
          .split(",")
          .filter((el) => el)
          .map((el) => el * 1)
      : []
  );
  const [editMileStonePop, setEditMileStonePop] = useState(false);
  const [slectedMilestone, setSelectedMilestone] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showEditArea, setShowEditArea] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const epicUrl = searchParams.get("milestone");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const isMobile = useResponsive("down", "md");
  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const boardNameTitle = boardName?.data?.data?.responseData?.board_name
    .substring(0, 3)
    .toUpperCase();

  const { epics } = useEpic(id);

  const names = epics?.data?.data?.responseData || [];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleCheckboxChange = (id) => {
    searchParams.delete("noMilestone");
    setEpic((prevEpic) => {
      if (prevEpic.includes(id)) {
        updateSearchParams(
          "milestone",
          prevEpic.filter((n) => n !== id)
        );
        searchParams.delete("milestone");
        return prevEpic.filter((n) => n !== id);
      } else {
        updateSearchParams("milestone", [id]);
        return [id];
      }
    });
  };

  const handleNoMilestone = () => {
    if (noMileStoneSearch === "true") {
      updateSearchParams("noMilestone", "false");
    } else {
      searchParams.set("milestone", "");
      updateSearchParams("noMilestone", "true");
    }
  };

  useEffect(() => {
    const data = searchParams
      .get("milestone")
      ?.split(",")
      ?.filter((el) => el)
      ?.map((el) => el * 1);
    dispatch(searchCard(data || []));
    setEpic(data || []);
  }, [epicUrl]);

  const showEditButton = (epic_id) => {
    setHoveredIndex(epic_id);
  };
  const handleShowEditArea = (id) => {
    // setShowEditArea(true);
    setSelectedMilestone(id);
    setEditMileStonePop(true);
  };
  const handleOpenMilestone = (idx) => {
    names.map((ele, index) => {
      if (index == idx) {
        updateSearchParams("milestone", ele?.epic_id);
      }
    });
  };
  const handleEditFieldClose = () => {
    setShowEditArea(false);
    setHoveredIndex(null);
  };

  const completedMileStones = names?.filter((item) => item.completed == true);
  const unCompletedMileStones = names?.filter(
    (item) => item.completed == false
  );
  return (
    <>
      {isMobile ? (
        <Box>
          <div
            style={{
              maxHeight: "165px",
              overflowY: "auto",
              minHeight: "130px",
            }}
          >
            {showNoMile && (
              <MenuItem
                sx={{
                  height: "30px",
                  minHeight: "30px",
                  padding: "12px 0px !important ",
                }}
                // onMouseEnter={() => showEditButton()}
                // onMouseLeave={handleEditFieldClose}
              >
                <label class="custom-checkbox major-div">
                  <input
                    type="checkbox"
                    onClick={() => handleNoMilestone()}
                    // id={`epic-list-checkbox-${}`}
                    checked={noMileStoneSearch === "true"}
                  />
                  <span class="checkmark"></span>
                  <div className="epic-name-id-div-noMilestone">
                    <p className="epic-name">No milestone</p>
                  </div>
                </label>
              </MenuItem>
            )}
            {unCompletedMileStones?.map((item, index) => (
              <React.Fragment key={item?.epic_id}>
                <MenuItem
                  sx={{
                    height: "30px",
                    minHeight: "30px",
                    padding: "12px 0px !important ",
                  }}
                  onMouseEnter={() => showEditButton(item?.epic_id)}
                  onMouseLeave={handleEditFieldClose}
                >
                  <label class="custom-checkbox major-div">
                    <input
                      type="checkbox"
                      onClick={() => handleCheckboxChange(item?.epic_id)}
                      id={`epic-list-checkbox-${index}`}
                      checked={epic.indexOf(item?.epic_id) > -1}
                    />
                    <span class="checkmark"></span>
                    <div className="epic-name-id-div">
                      <p className="epic-name">
                        {showEditArea && hoveredIndex === item.epic_id ? (
                          <EditEpic
                            epicId={item.epic_id}
                            epicName={item?.epic_name}
                            setShowEditArea={setShowEditArea}
                          />
                        ) : (
                          item?.epic_name
                        )}
                      </p>
                      <p className="epic-id">
                        {!showEditArea &&
                          `${boardNameTitle} - ${item?.epic_id}`}
                      </p>
                    </div>
                  </label>
                </MenuItem>
                {index < names.length - 1 && <Divider />}
              </React.Fragment>
            ))}
            {epicUrl && (
              <EditMIlestone
                open={editMileStonePop}
                handleClose={() => setEditMileStonePop(false)}
              />
            )}

            {completedMileStones.length > 0 && (
              <div
                className="completed-epics"
                style={{
                  backgroundColor: "rgba(242, 246, 254, 1)",
                  padding: "6px",
                }}
              >
                <p>Completed</p>
              </div>
            )}

            {completedMileStones.length === 0 &&
            unCompletedMileStones.length === 0 ? (
              <div
                className="completed-epics"
                style={{
                  backgroundColor: "rgba(242, 246, 254, 1)",
                  padding: "6px",
                  height: "100px",
                }}
              >
                <p>No milestone found</p>
              </div>
            ) : (
              <Divider />
            )}

            {completedMileStones?.map((item, index) => (
              <React.Fragment key={item?.epic_id}>
                <MenuItem
                  sx={{
                    height: "40px",
                    minHeight: "36px",
                    padding: "12px 0px !important ",
                  }}
                  onMouseEnter={() => showEditButton(item?.epic_id)}
                  onMouseLeave={handleEditFieldClose}
                >
                  <label class="custom-checkbox major-div">
                    <input
                      type="checkbox"
                      onClick={() => handleCheckboxChange(item?.epic_id)}
                      id={`epic-list-checkbox-${index}`}
                      checked={epic.indexOf(item?.epic_id) > -1}
                    />
                    <span class="checkmark"></span>
                    <div className="epic-name-id-div">
                      <p className="epic-name">
                        {showEditArea && hoveredIndex === item.epic_id ? (
                          <EditEpic
                            epicId={item.epic_id}
                            epicName={item?.epic_name}
                            setShowEditArea={setShowEditArea}
                          />
                        ) : (
                          item?.epic_name
                        )}
                      </p>
                      <p className="epic-id">
                        {!showEditArea &&
                          `${boardNameTitle} - ${item?.epic_id}`}
                      </p>
                    </div>
                  </label>
                </MenuItem>
                {index < names.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
          {isAdmin() || boardNameDetails?.data?.responseData?.is_owner ? (
            <CreateEpic />
          ) : (
            ""
          )}
        </Box>
      ) : (
        <>
          <Button
            variant="outlined"
            color="primary"
            id="epics-board-dropdown"
            aria-controls={open ? "user-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className="white-button epics-btn"
            sx={{
              height: "38px",
              padding: "5px 10px 5px 2px",
              "&:hover": {
                outline: "none",
                backgroundColor: "rgb(240 249 254)",
                border: "1px solid #1b3e61",
              },
            }}
          >
            <img
              src={apicIcon}
              className="header-filter-icon"
              alt="not found"
            />{" "}
            &nbsp;{" "}
            <p className="header-action-button-text" style={{ width: "65px" }}>
              Milestones
            </p>
          </Button>

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "epics-board-dropdown",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                width: "350px",
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            // className="epic-list"
          >
            <div style={{ maxHeight: "222px", overflowY: "auto" }}>
              {showNoMile && (
                <>
                  <MenuItem
                    sx={{
                      height: "30px",
                      minHeight: "30px",
                      padding: "12px 0px !important ",
                    }}
                    // onMouseEnter={() => showEditButton()}
                    // onMouseLeave={handleEditFieldClose}
                  >
                    <label class="custom-checkbox major-div-noMilestone">
                      <input
                        type="checkbox"
                        onClick={() => handleNoMilestone()}
                        // id={`epic-list-checkbox-${}`}
                        checked={noMileStoneSearch === "true"}
                      />
                      <span class="checkmark"></span>
                      <div className="epic-name-id-div-noMilestone">
                        <p className="epic-name">No milestone</p>
                      </div>
                    </label>
                  </MenuItem>
                  <Divider />
                </>
              )}
              {unCompletedMileStones?.map((item, index) => (
                <React.Fragment key={item?.epic_id}>
                  <MenuItem
                    sx={{
                      height: "40px",
                      minHeight: "36px",
                      padding: "12px 8px !important ",
                    }}
                    onMouseEnter={() => showEditButton(item?.epic_id)}
                    onMouseLeave={handleEditFieldClose}
                  >
                    <label class="custom-checkbox major-div">
                      <input
                        type="checkbox"
                        onClick={() => handleCheckboxChange(item?.epic_id)}
                        id={`epic-list-checkbox-${index}`}
                        checked={epic.indexOf(item?.epic_id) > -1}
                      />
                      <span class="checkmark"></span>
                      <div className="epic-name-id-div">
                        <p className="epic-name">
                          {showEditArea && hoveredIndex === item.epic_id ? (
                            <EditEpic
                              epicId={item.epic_id}
                              epicName={item?.epic_name}
                              setShowEditArea={setShowEditArea}
                            />
                          ) : (
                            item?.epic_name
                          )}
                        </p>
                        <p className="epic-id">
                          {!showEditArea &&
                            `${boardNameTitle} - ${item?.epic_id}`}
                        </p>
                      </div>
                    </label>
                  </MenuItem>
                  {index < names.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {epicUrl && (
                <EditMIlestone
                  open={editMileStonePop}
                  handleClose={() => setEditMileStonePop(false)}
                />
              )}

              {completedMileStones.length > 0 && (
                <div className="completed-epics">
                  <p>Completed</p>
                </div>
              )}
              <Divider />
              {completedMileStones?.map((item, index) => (
                <React.Fragment key={item?.epic_id}>
                  <MenuItem
                    sx={{
                      height: "40px",
                      minHeight: "36px",
                      padding: "12px 8px !important ",
                    }}
                    onMouseEnter={() => showEditButton(item?.epic_id)}
                    onMouseLeave={handleEditFieldClose}
                  >
                    <label class="custom-checkbox major-div">
                      <input
                        type="checkbox"
                        onClick={() => handleCheckboxChange(item?.epic_id)}
                        id={`epic-list-checkbox-${index}`}
                        checked={epic.indexOf(item?.epic_id) > -1}
                      />
                      <span class="checkmark"></span>
                      <div className="epic-name-id-div">
                        <p className="epic-name">
                          {showEditArea && hoveredIndex === item.epic_id ? (
                            <EditEpic
                              epicId={item.epic_id}
                              epicName={item?.epic_name}
                              setShowEditArea={setShowEditArea}
                            />
                          ) : (
                            item?.epic_name
                          )}
                        </p>
                        <p className="epic-id">
                          {!showEditArea &&
                            `${boardNameTitle} - ${item?.epic_id}`}
                        </p>
                      </div>
                    </label>
                  </MenuItem>
                  {index < names.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {isAdmin() || boardNameDetails?.data?.responseData?.is_owner ? (
                <>
                  <CreateEpic />
                  <Divider />
                  {names && names.length > 0 && (
                    <MergeMilestone milestones={names} />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </Menu>
        </>
      )}
    </>
  );
};

export default EpicButton;
