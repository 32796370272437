import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../../components/Loader";
import { checkLinkValid } from "../../../sevices/apiAuth";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const uuid = urlParams.get("uuid");

  const [isLoading, setIsLoading] = useState(true);
  const [isLinkValid, setIsLinkValid] = useState(null);

  useEffect(() => {
    if (uuid) {
      checkLinkValid(uuid)
        .then((res) => {
          const linkValid = res.data.data.responseData.link_valid;
          setIsLinkValid(linkValid);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [uuid]);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    );
  }

  if (isLinkValid) {
    return uuid && <ResetPasswordForm uuid={uuid} />;
  } else {
    return <TimeExpire />;
  }
};

export default ResetPassword;

export const TimeExpire = () => {
  const navigate = useNavigate();
  useEffect(() => {
    toast.error("Link has expired");
    navigate("/forgotpassword");
  }, []);

  // return <div>Your password reset link has expired.</div>;
};
