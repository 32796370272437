import { localStorageInstance } from "./localStorage";

export function isAdmin() {
  const userData = localStorageInstance.getItem("role");
  return userData == "Super Admin";
}

export function currentUserId() {
  const userData = localStorageInstance.getItem("userId");
  return userData;
}

export const getAPIBaseUrl = () => {
  const url = `${window.location.protocol}//${window.location.hostname}/api`;

  return window.location.hostname === "localhost"
    ? "https://sofmen.taskboard.sofmen.com/api"
    : url;
};
