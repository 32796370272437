import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Stack, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import { useResponsive } from "../hooks/useResponsive";
import { assignCard } from "../sevices/apiCards";
import { removeHtmlTagsAndEntities } from "../utils/helpers";

const modules = {
  toolbar: [
    [],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [], // Add color and background color options
    [],
    [],
  ],
};

const CommentForm = ({ cardId, userId, onClose }) => {
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");
  const isSmallDevice = useResponsive("between", 200, 380);
  const validationSchema = Yup.object().shape({
    comment: Yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { comment: "" },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: assignCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries(["card", cardId]);
      queryClient.invalidateQueries({
        queryKey: ["card", `${cardId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });

      onClose();
      toast.success(res.data.message);
    },
    onError: (error) => {
      toast.error(error.response.data.message);
      onClose();
    },
  });

  // Handle form submission
  const onSubmit = (data) => {
    console.log("Form data:", cardId, userId, data);
    // Add your form submission logic here
    mutate({
      card_id: cardId,
      data: { assigned_to_user_id: userId, comment: data.comment },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div style={{ marginTop: "5px", height: "208px" }}>
        <ReactQuill
          value={methods.getValues("comment")}
          onChange={(value) =>
            methods.setValue(
              "comment",
              removeHtmlTagsAndEntities(value).length === 0 ? "" : value
            )
          }
          modules={modules}
          style={{ height: "170px" }}
        />
      </div>
      <Typography className={"comments-react-quill"}>
        {methods.formState.errors.comment?.message}
      </Typography>
      <Stack
        sx={{
          justifyContent: "end",
          marginTop: isMobile ? (isSmallDevice ? "60px" : "35px") : "20px",
        }}>
        <Button
          type="submit"
          variant="contained"
          disabled={isPending}
          sx={{
            bgcolor: "#1B3E61",
            fontSize: isMobile ? "14px" : "16px",
            padding: isMobile ? "6px 16px" : "10px 16px",
            fontWeight: "600",
            textTransform: "capitalize",
            minWidth: isMobile ? "0px" : "130px",
            alignSelf: "end",
          }}
          onClick={(e) => e.stopPropagation()}>
          Send
        </Button>
      </Stack>
    </form>
  );
};

export default CommentForm;
