import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCard } from "../../sevices/apiCards";
import toast from "react-hot-toast";
import "./ChangeStatus.scss";
import { useWorkflowById } from "../../utils/reactQuery/workflows";

export default function ChangeStatus({
  boardId,
  checkCondition,
  workflow_id,
  cardId,
  workflowStepId,
}) {
  const [status, setStatus] = React.useState("");
  const [cachedData, setCachedData] = React.useState("");
  const queryClient = useQueryClient();
  const { data: workflows } = useWorkflowById(workflow_id, boardId);

  React.useEffect(() => {
    if (boardId && workflows) {
      setCachedData(workflows);
    }
  }, [boardId, workflows]);

  React.useEffect(() => {
    setStatus(workflowStepId);
  }, [workflowStepId]);

  const { mutate: updateMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries(["cards", boardId]);
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });

      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
    },
  });

  const handleChange = (event) => {
    updateMutate({
      card_id: cardId,
      data: { workflow_step_id: event.target.value },
    });
    setStatus(event.target.value);
  };

  const showStatus =
    cachedData &&
    cachedData?.data?.data?.responseData?.filter(
      (workflow) => workflow.workflow_step_id == workflow_id
    )[0];

  return (
    <Box sx={{}}>
      {checkCondition ? (
        <FormControl size="small" className="card-status-dropdown-parent">
          <Select
            labelId="demo-simple-select-label"
            value={showStatus && status}
            onChange={handleChange}
            className="card-status-dropdown">
            {cachedData &&
              cachedData?.data?.data?.totalRecord &&
              cachedData?.data?.data?.responseData.map((workflow, index) => (
                <MenuItem value={workflow.workflow_step_id}>
                  {workflow.status}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <div className="project-status d-flex justify-content-center align-items-center">
          <button className="project-status-button">
            {showStatus && showStatus?.status}
          </button>
          <div className="project-status-icon">
            {/* <KeyboardArrowDownIcon/> */}
          </div>
        </div>
      )}
    </Box>
  );
}
