import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Slide,
  styled,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import NavigationBoards from "./components/Boards";
import "./dashboard.scss";
import { localStorageInstance } from "../../utils/localStorage";
import { useLoginUser } from "../../utils/reactQuery/users";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { user } from "../../redux/usersSlice";
import Profile from "../../components/Header/Profile";
import { useResponsive } from "../../hooks/useResponsive";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../styles/svg/sofmenLogo.svg";
import { setSideBarDrawer } from "../../redux/Sidebar";
import { useAssets } from "../../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../../utils/constants";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 259,
  transition: "transform 1s ease-in-out",
}));

export default function Dashboard() {
  const userId = localStorageInstance.getItem("userId");
  const { data, isLoading } = useLoginUser(userId);
  const dispatch = useDispatch();
  const isMobile = useResponsive("down", "md");
  const [sidebar, setSideBar] = useState(false);
  const sideBarDrawer = useSelector(
    (state) => state.sideBarDrawer.sideBarDrawer
  );
  const { logo } = useAssets();
  const apiUrl = getAPIBaseUrl();
  if (data) {
    dispatch(user(data.data.data.responseData));
  }

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children ?? <div />}
      </Slide>
    );
  }
  return (
    <Box>
      {/* <Header /> */}
      {isMobile ? (
        <div>
          <HideOnScroll>
            <AppBar sx={{ backgroundColor: "#F2F6FE" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => dispatch(setSideBarDrawer(true))}>
                  <MenuIcon sx={{ color: "#1B3E61" }} />
                </IconButton>
                <img src={`${apiUrl}/${logo}`} alt="logo" width={140} />
              </Toolbar>
            </AppBar>
          </HideOnScroll>
          <Toolbar />
          <Outlet />
          {/* <Container>
            <Box sx={{ my: 2 }}>
            </Box>
          </Container> */}
        </div>
      ) : (
        <Box className="wrapper_dashboard">
          <Box className="wrapper_sidebar">
            <div className="logo-container">
              <img
                src={`${apiUrl}/${logo}`}
                className="board-logo"
                alt="Sofmen"
              />
            </div>
            <Profile />
            <Divider />
            <NavigationBoards />
          </Box>
          <Box className="wrapper_main_container">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="main-container">
                  <Outlet />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <Drawer
        open={sideBarDrawer}
        anchor="left"
        onClose={() => dispatch(setSideBarDrawer(false))}>
        <AnimatedDrawerContent
          style={{
            transform: sideBarDrawer ? "translateX(0)" : "translateX(100%)",
          }}>
          <Box className="wrapper_dashboard">
            <Box className="wrapper_sidebar">
              <div className="logo-container">
                <img
                  src={`${apiUrl}/${logo}`}
                  className="board-logo"
                  alt="Sofmen"
                />
              </div>
              <Profile />
              <Divider />
              <NavigationBoards />
            </Box>
          </Box>
        </AnimatedDrawerContent>
      </Drawer>
    </Box>
  );
}
