import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import { MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import arrow from "../../../../styles/svg/arrow.svg";
import Field from "../../../../components/field";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mergeMilestone } from "../../../../sevices/apiEpic";
export default function MergeMilestonePopUp({
  open,
  close,
  unlockedMilestone,
}) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");

  const [currentMilestone, setCurrentMilestone] = React.useState(null);
  const [selectedTargetMilestone, setSelectedTargetMilestone] =
    React.useState(null);
  const { id } = useParams();
  const [isloading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit: updateBoardSubmit,
    formState: {},
    getValues,
  } = useForm({
    targetStep: "None",
  });

  const { mutate } = useMutation({
    mutationFn: mergeMilestone,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`epic`, id],
      });

      close(false);
      toast.success(res.data.message);
      setIsLoading(false);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
    },
  });

  const targetMilestone = unlockedMilestone.filter(
    (item) => item.epic_id !== currentMilestone
  );

  const sourceMilestone = unlockedMilestone.filter(
    (item) => item.epic_id !== selectedTargetMilestone
  );

  const onSubmit = (data) => {
    setIsLoading(true);
    mutate(data);
  };

  const handleClose = () => {
    close(false);
  };
  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh !important",
            "& .MuiPaper-root": {
              borderRadius: "8px",
              maxWidth: "494px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="move-ticket-dialog-title"
        >
          {"Merge Milestone"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: isMobile ? 1 : 9,
            padding: "8px",
            color: (theme) => theme.palette.grey[500],
            "&:hover": {},
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="move-ticket-dialog-content">
          <DialogContentText className="move-ticket-dialog-contentText">
            Modify the status of the task. This allow you to update the status
            to reflect any changes needed before transfering.
          </DialogContentText>
        </DialogContent>
        <form onSubmit={updateBoardSubmit(onSubmit)}>
          <DialogContent className="move-ticket-form-dialogContent">
            <div
              className={
                isMobile
                  ? "mobile-ticket-reasign-step-container"
                  : "ticket-reasign-step-container"
              }
            >
              <div
                className={
                  isMobile
                    ? "mobile-current-target-board-name"
                    : "current-target-board-name"
                }
              >
                <p> Source milestone </p>
                <div className="initial-step">
                  <Field
                    sx={{
                      width: isMobile ? "100%" : "180px",
                    }}
                    className="mui-status-field"
                    required
                    value={getValues(`currentMilestone`)}
                    select
                    {...register(`currentMilestone`)}
                  >
                    {sourceMilestone.length > 0 ? (
                      sourceMilestone?.map((option) => (
                        <MenuItem
                          key={`option.epic_id`}
                          id={`option.epic_id`}
                          value={option.epic_id}
                          onClick={() => setCurrentMilestone(option.epic_id)}
                        >
                          {option.epic_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Milestone</MenuItem>
                    )}
                  </Field>
                </div>
              </div>

              <img src={arrow} alt="" />
              <div
                className={
                  isMobile
                    ? "mobile-current-target-board-name"
                    : "current-target-board-name"
                }
              >
                <p>Target Milestone</p>
                <div className="initial-step">
                  <Field
                    sx={{
                      width: isMobile ? "100%" : "180px",
                    }}
                    className="mui-status-field"
                    required
                    value={getValues(`targetMilestone`)}
                    select
                    {...register(`targetMilestone`)}
                  >
                    {targetMilestone.length > 0 ? (
                      targetMilestone?.map((option) => (
                        <MenuItem
                          key={`option.epic_id`}
                          id={`option.epic_id`}
                          value={option.epic_id}
                          onClick={() =>
                            setSelectedTargetMilestone(option.epic_id)
                          }
                        >
                          {option.epic_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Milestone</MenuItem>
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className="mergeMilestone-dialogActions">
            <Button
              className="remove-step-btn-save"
              autoFocus
              variant="contained"
              disabled={
                isloading ||
                currentMilestone == null ||
                selectedTargetMilestone == null
              }
              onClick={updateBoardSubmit(onSubmit)}
            >
              <div className="btn-content">Update</div>
            </Button>
            <Button
              className="remove-step-btn-cancel"
              autoFocus
              variant="outlined"
              onClick={handleClose}
            >
              <div className="btn-content">Cancel</div>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
