import React from "react";
import { useTeams } from "../../../../utils/reactQuery/teams";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Item from "./Item";

function ByTeamTab({ worklogs }) {
  const { teams } = useTeams();

  return (
    <div>
      {teams?.data?.data?.responseData?.map((team) => (
        <Accordion sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            {team.team}
          </AccordionSummary>
          <AccordionDetails className="pl-38">
            {worklogs.filter((worklog) =>
              team.users.some(
                (user) => user.user_id === worklog.cardlog.user.user_id
              )
            ).length > 0 ? (
              worklogs
                .filter((worklog) =>
                  team.users.some(
                    (user) => user.user_id === worklog.cardlog.user.user_id
                  )
                )
                .map((worklog, idx) => <Item worklog={worklog} key={idx} />)
            ) : (
              <p className="align_text_center">No worklog found</p>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default ByTeamTab;
