import React, { useEffect } from "react";
import { useAssets } from "../utils/reactQuery/domainAssets";
import { getAPIBaseUrl } from "../utils/constants";

const DynamicFavicon = () => {
  const { icon } = useAssets();
  const apiUrl = getAPIBaseUrl();
  const favIcon = apiUrl + "/" + icon;

  useEffect(() => {
    const updateFavicon = (iconUrl) => {
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = iconUrl;
      } else {
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = iconUrl;
        document.head.appendChild(newLink);
      }
    };

    // Update favicon when icon prop changes
    if (favIcon) {
      updateFavicon(favIcon);
    }
  }, [favIcon]);

  return null; // This component doesn't render anything
};

export default DynamicFavicon;
