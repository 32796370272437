import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import GlobalSearch from "../../components/Search";
import { useWatching, useWatchlistUsers } from "../../utils/reactQuery/users";
import MuiTable from "../admin/board/components/BoardTable/MuiTbale";
import {
  useUserWorkflowList,
  useWorkflowById,
} from "../../utils/reactQuery/workflows";
import Kanban from "../myTask/components/KanbanBoard/Kanban";
import Loader from "../../components/Loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { filterUser } from "../../redux/usersSlice";
import { useParams, useSearchParams } from "react-router-dom";
import gridActiveIcon from "../../styles/svg/grid-view-active.svg";
import gridInactiveIcon from "../../styles/svg/grid-view-inactive.svg";
import listActiveIcon from "../../styles/svg/list-view-active.svg";
import listInactiveIcon from "../../styles/svg/list-view-inactive.svg";
import manageColumnIcon from "../../styles/svg/Vector.svg";
import { removeHtmlTagsAndEntities, truncateText } from "../../utils/helpers";
import { useCards } from "../../utils/reactQuery/cards";
import { debounce } from "lodash";
import { searchCard, setGridView } from "../../redux/SearchSlice";
import _ from "lodash";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import { useSetMultipleParams } from "../../hooks/useSetMultipleParams";
import { useResponsive } from "../../hooks/useResponsive";
import filterIcon from "../../styles/svg/epic-icon.svg";
import { localStorageInstance } from "../../utils/localStorage";
import { dataGridColumn } from "./DataGridColumn";
import OpenCardDetail from "../board/components/Kanban/openCardDetail";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

export default function WatchingComp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const workflow_id = searchParams.get("workflow_id");
  const { data: workflows } = useWorkflowById(workflow_id, workflow_id);
  const { data, isLoading, isRefetching } = useWatching(workflow_id);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(dataGridColumn);
  const [anchorEl, setAnchorEl] = useState(null);
  const [setMultipleSearchParams] = useSetMultipleParams();
  const [genericId, setGenericId] = useState(false);
  const open = Boolean(anchorEl);
  const [selectedColumn, setSelectedColumn] = useState({
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
  });
  const filterUser = searchParams.get("search");
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const searchText = useSelector((state) => state.search.searchText);
  const cardId = searchParams.get("card_id");
  const [projects, setProjects] = useState([]);
  const { id } = useParams();
  const [workflowId, setWorkflowId] = useCustomSearchParams("workflow_id");
  const isMobile = useResponsive("down", "md");
  const { userWorkflows } = useUserWorkflowList();
  const [filterDrawer, setFilterDrawer] = useState(false);
  // const { cards } = useCards(id);
  const trigger = useScrollTrigger();
  const [inputValue, setInputValue] = useState("");
  // const workflow_id = searchParams.get("workflow_id");
  // const { data: workflows } = useWorkflowById(workflow_id, workflow_id);

  useEffect(() => {
    // console.log(
    //   "workflows for user list == ",
    //   userWorkflows?.data?.data?.responseData[0]?.workflow_id
    // );
    if (workflowId) {
      //eat first start do nothing
    } else {
      if (userWorkflows?.data?.data?.responseData[0]?.workflow_id) {
        setWorkflowId(
          "workflow_id",
          userWorkflows?.data?.data?.responseData[0]?.workflow_id
        );
      }
    }
  }, [userWorkflows, workflowId]);

  const gridView = useSelector((state) => state.search.gridView);

  const getCradStatus = (id) => {
    const tamp =
      workflows?.data?.data?.responseData.filter(
        (ele) => ele.workflow_step_id == id
      )[0]?.status || "";
    return tamp;
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300) // 300ms delay
  ).current;

  const handleToggleColumn = (field) => {
    setColumns((prevColumns) =>
      prevColumns.some((col) => col.field === field)
        ? prevColumns.filter((col) => col.field !== field)
        : [...prevColumns, columns.find((col) => col.field === field)]
    );
  };
  const filterUserById = (userId) => {
    dispatch(filterUser(userId));
    updateSearchParams("search", userId);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleSelectColumn = (slec) => {
    const temp = { ...selectedColumn };
    temp[slec] = !selectedColumn[slec];
    setSelectedColumn(temp);
  };
  console.log("selected cahnge", selectedColumn);

  useEffect(() => {
    // Define the debounce delay (e.g., 300 milliseconds)
    const debounceDelay = 1000;
    let allFilteredCards = data?.data?.data?.responseData;

    // Create a debounced version of the filter function
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (el) =>
            (el?.card.title
              ? `${el.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (el.card.assigned_to_user?.first_name
              ? `${el.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        setProjects(allFilteredCards);
      }
    }, debounceDelay);
    if (filterUser) {
      allFilteredCards = allFilteredCards?.filter(
        (el) => el.card.assigned_to_user_id == filterUser
      );
    }
    if (epicSearch && epicSearch?.length > 0) {
      allFilteredCards = allFilteredCards?.filter((item) =>
        epicSearch.includes(item.card.epic_id)
      );
    }
    if (searchText) {
      // Call the debounced filter function whenever searchText, id, or cards change
      debouncedFilter();
    }

    setProjects(allFilteredCards);

    return () => {
      // Cleanup the debounce function on component unmount
      debouncedFilter.cancel();
    };
  }, [searchText, filterUser, epicSearch, id, data]);

  const checkGenric = (id) => {
    const temp = userWorkflows?.data?.data?.responseData.filter((ele) => {
      return id == ele.workflow_id;
    });
    if (temp) {
      const isGeneric = temp[0]?.is_generic;
      setGenericId(isGeneric);

      setMultipleSearchParams([
        { name: "workflow_id", value: id },
        { name: "is_generic", value: isGeneric },
      ]);
    }
  };

  return (
    <>
      {projects && cardId && (
        <OpenCardDetail
          cardsOnBoard={projects.map((el) => {
            return {
              ...el,
              ...el.card,
            };
          })}
        />
      )}
      {isMobile ? (
        <Grid
          container
          item
          xs={12}
          className="remove-container-width fixed-ui"
          sx={{ top: trigger ? "0px" : isMobile ? "51px" : "" }}
          justifyContent={"center"}
          pb={"16px !important"}
          pt={trigger ? "24px !important" : "20px !important"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={6}
            md={12}
            lg={3}
            className="d-flex align-items-center"
          >
            <Typography fontWeight={600}>Watching Cards</Typography>
          </Grid>
          <Grid item xs={6} md={7} lg={9}>
            <Stack direction="row" justifyContent="end">
              <img
                src={filterIcon}
                alt="filter"
                style={{ marginRight: "8px" }}
                onClick={() => setFilterDrawer(true)}
              />
            </Stack>{" "}
          </Grid>
        </Grid>
      ) : (
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <p className="board-heading-name">Watching Cards</p>
          <Stack direction="row">
            {/* <AvatarGroup
            total={usersList?.length}
            // onClick={(event) => setAnchorEl(event.currentTarget)}}
            className={`avatar-group cursor-pointer ${"avatar-group-border"}`}
          >
            {usersList?.map((el) => (
                <Avatar
                  key={`user-avtar-${el.user_id}`}
                  onClick={() => filterUserById(el.user_id)}
                  username={el.first_name}
                  user_image={el.user_image}
                  title={el.username}
                  className={`header-avatar ${
                    el.user_id == filterUserId ? "active-avtar" : ""
                  }`}
                  style={ {
                      backgroundColor: el?.profile_color?.background,
                      color: el?.profile_color?.text,
                    }
                  }
                >
                  {createAvtarName(el?.first_name)}
                </Avatar>
              ))}
          </AvatarGroup> */}
            <div className="d-flex align-items-center mr-2">
              <p className="filter-text">Select Workflow:</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={workflowId}
                sx={{ height: "38px", width: "150px", fontSize: "15px" }}
                onChange={(event) =>
                  // setWorkflowId("workflow_id", event.target.value)
                  checkGenric(event.target.value)
                }
              >
                {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
                  return (
                    <MenuItem value={ele.workflow_id}>
                      {ele.workflow_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Stack className="grid-list-cont">
              <img
                src={gridView ? gridActiveIcon : gridInactiveIcon}
                alt="gridIcon"
                onClick={
                  () => {
                    if (gridView) {
                    } else {
                      dispatch(setGridView());
                    }
                    localStorageInstance.setItem({
                      key: "showGridView",
                      value: true,
                    });
                  }
                  //  (gridView ? "" : dispatch(setGridView()))
                }
              />
              <img
                src={gridView ? listInactiveIcon : listActiveIcon}
                alt="listIcon"
                onClick={
                  () => {
                    if (gridView) {
                      dispatch(setGridView());
                    } else {
                    }
                    localStorageInstance.setItem({
                      key: "showGridView",
                      value: false,
                    });
                  }

                  // (gridView ? dispatch(setGridView()) : "")
                }
              />
            </Stack>
            <GlobalSearch
              placeholder={"Search.."}
              searchText={inputValue}
              handleChange={handleChange}
            />
          </Stack>
        </Stack>
      )}
      {isLoading || isRefetching ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <>
          {data && !isLoading && !isRefetching && workflows
            ? gridView && (
                <Kanban
                  cards={data?.data?.data?.responseData || []}
                  workflowSteps={workflows?.data?.data?.responseData.sort(
                    (a, b) => a.step_sequence - b.step_sequence
                  )}
                />
              )
            : ""}
          {!gridView && (
            <div>
              <Stack
                direction="row"
                justifyContent="end"
                my={3}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <img
                  src={manageColumnIcon}
                  width={14}
                  alt="manage column"
                  style={{ cursor: "pointer" }}
                />
                <Typography className="manage-board">
                  Manage Board Columns
                </Typography>
              </Stack>
              <MuiTable
                subHeight={"210"}
                initialRows={
                  projects
                    ? projects?.map((ele) => {
                        return {
                          id: ele?.card?.card_id,
                          col1: ele?.card?.card_id,
                          col2: ele?.card?.title,
                          col3: ele?.card?.workflow_step_id
                            ? getCradStatus(ele?.card?.workflow_step_id)
                            : "",
                          col4: ele?.card?.description
                            ? truncateText(
                                removeHtmlTagsAndEntities(
                                  ele?.card?.description
                                ),
                                85
                              )
                            : "",
                          col5: `${
                            ele?.card?.assigned_to_user?.first_name || ""
                          } ${ele?.card.assigned_to_user?.last_name || ""}`,
                          col6: ele?.card.labels?.join(" "),
                          col7: ele?.card?.epic?.epic_name,
                          col8: ele?.card?.allocated_hours,
                          col9: ele?.total_hours_spent,
                          col10: {
                            hoursSpent: ele?.total_hours_spent,
                            allotedHours: ele?.card?.allocated_hours,
                          },
                          col11: ele?.card?.due_date
                            ? moment(ele?.card?.due_date)
                                .local()
                                .format("MMMM D,YYYY")
                            : "",
                          col12: ele?.card?.date_created
                            ? moment(ele?.card?.date_created)
                                .local()
                                .format("MMMM D,YYYY")
                            : "",
                          col13: `${ele?.card?.reported_by?.first_name || ""} ${
                            ele?.card?.reported_by?.last_name || ""
                          }`,
                        };
                      })
                    : []
                }
                columns={columns.filter((ele) => selectedColumn[ele.field])}
              />
            </div>
          )}

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(false)}
            MenuListProps={{
              "aria-labelledby": "epics-board-dropdown",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className="assign-board-owner-list"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Object.values(selectedColumn).every(
                      (value) => value === true
                    )}
                    onChange={() =>
                      setSelectedColumn((prevObj) => {
                        // Create a new object with all values set to true
                        const newObj = Object.keys(prevObj).reduce(
                          (acc, key) => {
                            acc[key] = true;
                            return acc;
                          },
                          {}
                        );
                        return newObj;
                      })
                    }
                  />
                }
                label="Select All"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col3}
                    onChange={() => handleSelectColumn("col3")}
                  />
                }
                label="Card Status"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col4}
                    onChange={() => handleSelectColumn("col4")}
                  />
                }
                label="Card Description"
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col5}
                    onChange={() => handleSelectColumn("col5")}
                  />
                }
                label="Assignee"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col6}
                    onChange={() => handleSelectColumn("col6")}
                  />
                }
                label="Label"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col7}
                    onChange={() => handleSelectColumn("col7")}
                  />
                }
                label="Epic"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col8}
                    onChange={() => handleSelectColumn("col8")}
                  />
                }
                label="Alloted Hours"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col9}
                    onChange={() => handleSelectColumn("col9")}
                  />
                }
                label="Hours Spent"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col10}
                    onChange={() => handleSelectColumn("col10")}
                  />
                }
                label="Time Tracking"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col11}
                    onChange={() => handleSelectColumn("col11")}
                  />
                }
                label="Due Date"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col12}
                    onChange={() => handleSelectColumn("col12")}
                  />
                }
                label="Current Date"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedColumn.col13}
                    onChange={() => handleSelectColumn("col13")}
                  />
                }
                label="Reporter"
              />
            </FormGroup>
          </Menu>
        </>
      )}

      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}
        >
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}
          >
            <Box p={1}>
              <div>
                <p className="filter-text">Select Workflow:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}
                >
                  {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
                    return (
                      <Typography
                        className={"workflow_steps"}
                        sx={{
                          backgroundColor:
                            ele.workflow_id == workflowId && "#C5DCF3",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setWorkflowId("workflow_id", ele.workflow_id)
                        }
                      >
                        {ele.workflow_name}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}
