import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWorklogHistory } from "../../../../sevices/apiWorklog";
import Item from "./Item";
import { useDispatch } from "react-redux";
import { setWorkHours } from "../../../../redux/SearchSlice";
import Loader from "../../../../components/Loader";
import OpenCardDetail from "../../../board/components/Kanban/openCardDetail";
import TabsController from "./TabsController";
import ByTeamTab from "./ByTeamTab";
import ByBoardTab from "./ByBoardTab";

function History() {
  const [worklogs, setWorklogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const user = groupingUser.map((item) => item?.users).flat();
  const checkedList = useSelector((state) => state.search.boardSearch);
  const dateRange = useSelector((state) => state.search.dateRange);
  const epicSearch = useSelector((state) => state.search.epicSearch);
  const [currentTab, setCurrentTab] = useState("users");

  useEffect(() => {
    setLoading(true);
    getWorklogHistory(user, checkedList, dateRange, epicSearch)
      .then((res) => {
        // console.log(res.data.data.responseData);
        setWorklogs(res?.data?.data?.responseData);
        setLoading(false);
        dispatch(
          setWorkHours(
            res?.data?.data?.responseData.reduce(
              (accumulator, currentValue) => {
                return accumulator + currentValue.cardlog.hours_spent;
              },
              0
            )
          )
        );
      })
      .catch((err) => {
        console.log("err", err);
        if (err.code != "ERR_CANCELED") setLoading(false);

        setWorklogs([]);
      });
  }, [groupingUser, checkedList, dateRange, epicSearch]);

  return (
    <>
      <TabsController
        value={currentTab}
        onChange={(event, value) => setCurrentTab(value)}
      />
      <div className="worklog-scroll">
        {!loading && currentTab == "users" ? (
          worklogs?.map((worklog, idx) => <Item worklog={worklog} key={idx} />)
        ) : currentTab == "by-board" ? (
          <ByBoardTab worklogs={worklogs} />
        ) : (
          <ByTeamTab worklogs={worklogs} />
        )}

        {worklogs.length == 0 && !loading && (
          <>
            <p className="loader-center">Worklog Not Found</p>
          </>
        )}

        {loading && (
          <div className="loader-center">
            <Loader color={"black"} />
          </div>
        )}
        <OpenCardDetail />
      </div>
    </>
  );
}

export default History;
