import { TextField } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { updateCard } from "../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export default function CardTitleField({
  value,
  cardId,
  isReadOnly,
  height = 32,
}) {
  const { id: boardId } = useParams();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["card", cardId],
      });
      queryClient.invalidateQueries({
        queryKey: ["cards", boardId],
      });
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error Update card title", error);
      toast.error(error.response.data.message);
    },
  });

  const handleUpdatedTitle = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      mutate({
        card_id: cardId,
        data: { title: e.target.value },
      });
    }
  };

  return (
    <TextField
      variant="standard"
      onKeyDown={(e) => {
        // e.stopPropagation();
        handleUpdatedTitle(e);
      }}
      defaultValue={value}
      multiline
      InputProps={{
        disableUnderline: true, // <== added this
        readOnly: isReadOnly,
        style: {
          height: height,
          border: "none",
          fontSize: "14px",
        },
      }}
    />
  );
}
