import React, { useState } from "react";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTeams } from "../../../../utils/reactQuery/teams";
import Item from "./Item";
import Avatar from "../../../../components/Avatar/index";
import { createAvtarName, hasImage } from "../../../../utils/helpers";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandBoardIcon from "../../../../styles/svg/expand_board.svg";
import ShrinkBoard from "../../../../styles/svg/shrinkBoard.svg";
import PieChartComp from "./PieChart";

function ByBoardTab({ worklogs }) {
  const activeBoard = useSelector((state) => state.activeBoard.activeBoardList);
  const checkedList = useSelector((state) => state.search.boardSearch);
  const { teams } = useTeams();
  const [expandedPanel, setExpandedPanel] = useState(null);
  const totalHours = useSelector((state) => state.search.totalWorkHours);

  const handleExpandAccordianChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  const selectedBoard = checkedList.map((boardId) =>
    activeBoard.find((board) => board.board_id === boardId)
  );

  return (
    <div>
      {selectedBoard.length > 0 && (
        <div className="d-flex justify-content-center">
          <PieChartComp
            data={selectedBoard.map((boardData) => {
              return {
                name: boardData.board_name,
                value: worklogs
                  .filter(
                    (worklog) =>
                      worklog?.cardlog?.card?.board_id === boardData.board_id
                  )
                  .reduce((sum, item) => sum + item?.cardlog.hours_spent, 0),
              };
            })}
            totalHours={totalHours}
          />
        </div>
      )}
      <div className="worklog_board_cont">
        <div className="worklog_board_header">
          <p>Board Name</p>
          <p>Hours</p>
        </div>
        {selectedBoard.length > 0 ? (
          selectedBoard.map((boardData, idx) => {
            return (
              <Accordion
                key={idx}
                expanded={expandedPanel === idx}
                onChange={handleExpandAccordianChange(idx)}>
                <AccordionSummary
                  expandIcon={
                    expandedPanel === idx ? (
                      <img src={ShrinkBoard} />
                    ) : (
                      <img src={ExpandBoardIcon} />
                    )
                  }
                  aria-controls="panel1-content"
                  id="panel1-header">
                  <div className="worklog_board_accordian_header">
                    <div className="d-flex align-items-center">
                      <Avatar
                        key={`board-avtar-${boardData.board_id}`}
                        user_image={boardData.board_owner_image}
                        className="sidebar-boardData-owner user-avatar"
                        title={boardData.board_owner_name}
                        style={
                          !hasImage(boardData.board_owner_image) && {
                            bgcolor:
                              boardData?.board_owner_profile_color?.background,
                            color: boardData?.board_owner_profile_color?.text,
                          }
                        }>
                        {`${createAvtarName(
                          boardData.board_owner_name
                        )}`.toUpperCase()}
                      </Avatar>

                      <p className="worklog_board_name ml-2">
                        {boardData.board_name}
                      </p>
                    </div>
                    <p className="total_project_hours">
                      {worklogs
                        .filter(
                          (worklog) =>
                            worklog?.cardlog?.card?.board_id ===
                            boardData.board_id
                        )
                        .reduce(
                          (sum, item) => sum + item?.cardlog.hours_spent,
                          0
                        )}
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="pl-38">
                  {teams?.data?.data?.responseData?.map((team) => (
                    <Accordion sx={{ boxShadow: "none" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header">
                        {team.team}
                      </AccordionSummary>
                      <AccordionDetails>
                        {worklogs
                          .filter(
                            (worklog) =>
                              worklog?.cardlog?.card?.board_id ===
                              boardData.board_id
                          )
                          .filter((worklog) =>
                            team.users.some(
                              (user) =>
                                user.user_id === worklog.cardlog.user.user_id
                            )
                          ).length > 0 ? (
                          worklogs
                            .filter(
                              (worklog) =>
                                worklog?.cardlog?.card?.board_id ===
                                boardData.board_id
                            )
                            .filter((worklog) =>
                              team.users.some(
                                (user) =>
                                  user.user_id === worklog.cardlog.user.user_id
                              )
                            )
                            .map((worklog, idx) => (
                              <Item worklog={worklog} key={idx} />
                            ))
                        ) : (
                          <p className="align_text_center">No worklog found</p>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <p className="loader-center">No Board Selected</p>
        )}
      </div>
    </div>
  );
}

export default ByBoardTab;
