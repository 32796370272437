const ForgotPasswordMessage = Object.freeze({
  LINK_SEND_SUCCESS: "Check your inbox",
  LINK_SEND_SUCCESS_MESSAGE:
    "We have sent you a password reset link. Please check your email.",
  FORGOT_PASSWORD: "Reset Password",
  FORGOT_PASSWORD_MESSAGE:
    "Enter the email associated with your account, and we will send you instructions to reset your password.",
  CREATE_NEW_PASSWORD: "Create New Password",
  CREATE_NEW_PASSWORD_MESSAGE: "Secure your account by setting new password",
});

export default ForgotPasswordMessage;
