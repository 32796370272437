import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";
import manageColumnIcon from "../../../../styles/svg/Vector.svg";
import MuiTable from "../../../admin/board/components/BoardTable/MuiTbale";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { useCards } from "../../../../utils/reactQuery/cards";
import { dataGridColumn } from "../../../../components/dataGridColumn";
import OpenCardDetail from "../Kanban/openCardDetail";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateCardDialogListview from "./CreateCardDialogListview";
import { currentUserId, isAdmin } from "../../../../utils/constants";

export default function ListView({ workflowId, subHeight, boardName }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id: boardId } = useParams();
  const [columns, setColumns] = useState(dataGridColumn);
  const { cards } = useCards(boardId);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedColumn, setSelectedColumn] = useState({
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
  });
  const [boardCards, setBoardCards] = useState([]);
  const { data: workflows } = useWorkflowById(workflowId, boardId);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  const filterUser = searchParams.get("search");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const searchText = useSelector((state) => state.search.searchText);
  const cardId = searchParams.get("card_id");
  const statusSearch = searchParams.get("workflow_Step");
  const currentUserIdVariable = currentUserId();

  const havePermissionToEdit = (data) => {
    if (
      isAdmin() ||
      boardName?.owner_user_id == currentUserIdVariable ||
      data?.assigned_to_user_id == currentUserIdVariable ||
      data?.reporter == currentUserIdVariable
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectColumn = (selectedColumn) => {
    const temp = { ...selectedColumn };
    temp[selectedColumn] = !selectedColumn[selectedColumn];
    setSelectedColumn(temp);
  };

  const workflowSteps = workflows?.data?.data?.responseData;

  useEffect(() => {
    const debounceDelay = 1000;
    let allFilteredCards = cards?.data.data.responseData;

    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (cardData) =>
            (cardData?.card.title
              ? `${cardData.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (cardData.card.assigned_to_user?.first_name
              ? `${cardData.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        setBoardCards(allFilteredCards);
      }
    }, debounceDelay);
    if (filterUser) {
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData.card.assigned_to_user_id == filterUser
      );
    }
    if (epicSearch && epicSearch.length > 0) {
      allFilteredCards = allFilteredCards?.filter((cardData) =>
        epicSearch.includes(cardData.card.epic_id)
      );
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards?.filter(
        (cardData) => cardData?.card.epic_id == null
      );
    }

    if (searchText) {
      debouncedFilter();
    }

    if (statusSearch) {
      allFilteredCards = allFilteredCards?.filter((cardData) => {
        return cardData.card.workflow_step_id == statusSearch;
      });
    }

    setBoardCards(allFilteredCards);

    return () => {
      debouncedFilter.cancel();
    };
  }, [
    searchText,
    filterUser,
    epicSearch,
    boardId,
    cards,
    statusSearch,
    noMileStoneSearch,
  ]);

  const handleClickOpen = () => {
    setOpenCreateCard(true);
  };

  return (
    <>
      {boardCards && cardId && <OpenCardDetail cardsOnBoard={boardCards} />}
      {openCreateCard && workflowSteps && (
        <CreateCardDialogListview
          open={openCreateCard}
          handleClose={() => setOpenCreateCard(false)}
          workflowSteps={workflowSteps}
        />
      )}
      <div>
        <Stack
          direction="row"
          className="d-flex justify-content-end align-items-center"
          mb={3}>
          <Button
            onClick={handleClickOpen}
            className="theme-bg-color capitalize mr-3"
            variant="contained"
            startIcon={<img src={addIcon} />}
            sx={{
              position: "static",
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
                boxShadow: 0,
              },
            }}>
            Create Card
          </Button>
          <Stack direction="row" justifyContent="end">
            <img
              src={manageColumnIcon}
              width={14}
              alt="manage column"
              style={{ cursor: "pointer" }}
            />
            <Typography
              className="manage-board"
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              Manage Board Columns
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ height: "calc(94vh - 154px )" }}>
          <MuiTable
            subHeight={subHeight}
            initialRows={
              boardCards
                ? boardCards?.map((boardCard) => {
                    return {
                      id: boardCard?.card?.card_id,
                      col1: boardCard?.card?.card_id,
                      col2: {
                        ...boardCard?.card,
                        isEditAllowed: havePermissionToEdit(boardCard?.card),
                      },
                      col3: {
                        card: {
                          ...boardCard?.card,
                          workflow_id: boardName?.workflow_id,
                        },
                        isEditAllowed: havePermissionToEdit(boardCard?.card),
                      },
                      col5: boardCard, // The boardCard contains the details of a particular card object.
                      col6: boardCard?.card?.labels?.join(" "),
                      col7: boardCard?.card,
                      col8: boardCard?.card?.allocated_hours,
                      col9: boardCard?.total_hours_spent,
                      col10: {
                        hoursSpent: boardCard?.total_hours_spent,
                        allotedHours: boardCard?.card.allocated_hours,
                      },
                      col11: boardCard?.card?.due_date
                        ? moment(boardCard?.card?.due_date)
                            .local()
                            .format("MMMM D,YYYY")
                        : "",
                      col12: boardCard?.card?.date_created
                        ? moment(boardCard?.card?.date_created)
                            .local()
                            .format("MMMM D,YYYY")
                        : "",
                      col13: `${
                        boardCard?.card.reported_by?.first_name || ""
                      } ${boardCard?.card?.reported_by?.last_name || ""}`,
                    };
                  })
                : []
            }
            columns={columns.filter((column) => selectedColumn[column?.field])}
            getRowHeight={() => "auto"}
            padding="12px"
          />
        </Box>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(false)}
          MenuListProps={{
            "aria-labelledby": "epics-board-dropdown",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          className="assign-board-owner-list">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Object.values(selectedColumn).every(
                    (value) => value === true
                  )}
                  onChange={() =>
                    setSelectedColumn((prevObj) => {
                      // Create a new object with all values set to true
                      const newObj = Object.keys(prevObj).reduce((acc, key) => {
                        acc[key] = true;
                        return acc;
                      }, {});
                      return newObj;
                    })
                  }
                />
              }
              label="Select All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col3}
                  onChange={() => handleSelectColumn("col3")}
                />
              }
              label="Card Status"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col5}
                  onChange={() => handleSelectColumn("col5")}
                />
              }
              label="Assignee"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col6}
                  onChange={() => handleSelectColumn("col6")}
                />
              }
              label="Label"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col7}
                  onChange={() => handleSelectColumn("col7")}
                />
              }
              label="Epic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col8}
                  onChange={() => handleSelectColumn("col8")}
                />
              }
              label="Alloted Hours"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col9}
                  onChange={() => handleSelectColumn("col9")}
                />
              }
              label="Hours Spent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col10}
                  onChange={() => handleSelectColumn("col10")}
                />
              }
              label="Time Tracking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col11}
                  onChange={() => handleSelectColumn("col11")}
                />
              }
              label="Due Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col12}
                  onChange={() => handleSelectColumn("col12")}
                />
              }
              label="Current Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col13}
                  onChange={() => handleSelectColumn("col13")}
                />
              }
              label="Reporter"
            />
          </FormGroup>
        </Menu>
      </div>
    </>
  );
}
