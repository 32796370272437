import { MenuItem } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Avatar from "../../../../components/Avatar";
import {
  setAllGroupUser,
  setGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import { createAvtarName } from "../../../../utils/helpers";

export default function Users({
  searchQuery,
  user,
  filteredItems,
  setSearchQuery,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isUnassignedChecked = searchParams.get("isUnassigned") === "true";
  const dispatch = useDispatch();
  const allUser = filteredItems.filter((el) => el.active);

  const selectUnassigned = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (isUnassignedChecked) {
      newSearchParams.delete("isUnassigned");
    } else {
      newSearchParams.set("isUnassigned", "true");
    }

    setSearchParams(newSearchParams);
  };

  const selectAll = () => {
    dispatch(
      setAllGroupUser(
        user.length == allUser.length
          ? []
          : [{ team: "Multiple", users: allUser }]
      )
    );
  };

  return (
    <>
      {!searchQuery && (
        <>
          <MenuItem onClick={selectUnassigned}>
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
                checked={isUnassignedChecked}
                onClick={(event) => {
                  console.log("Paragraph clicked");
                  event.stopPropagation();
                }}
              />
              <span class="checkmark"></span>
              Select unassigned Cards
            </label>
          </MenuItem>

          <MenuItem onClick={selectAll}>
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id={`board-list-my-task-checkbox-all-board`}
                checked={user.length == allUser.length}
                onClick={(event) => {
                  console.log("Paragraph clicked");
                  event.stopPropagation();
                }}
              />
              <span class="checkmark"></span>
              Select All Users
            </label>
          </MenuItem>
        </>
      )}
      {filteredItems
        .filter((el) => el.active)
        .sort(function (a, b) {
          return a.first_name.localeCompare(b.first_name);
        })
        .sort(
          (a, b) =>
            user.some((element) => element.user_id == b.user_id) -
            user.some((element) => element.user_id == a.user_id)
        )
        .map((el) => {
          return (
            <MenuItem
              key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
              onClick={() => {
                dispatch(setGroupUser(el));
                setSearchQuery("");
              }}>
              <label class="custom-checkbox">
                <input
                  type="checkbox"
                  id={`user-check-box-${el.user_id}`}
                  // checked={el.user_id == user.user_id}
                  checked={user.some(
                    (element) => element.user_id == el.user_id
                  )}
                  onClick={(event) => {
                    console.log("Paragraph clicked");
                    event.stopPropagation();
                  }}
                />
                <span class="checkmark"></span>

                <Avatar
                  key={`user-avtar-${el.user_id}`}
                  username={`${el.first_name} ${el.last_name}`}
                  user_image={el.user_image}
                  title={`${el.first_name} ${el.last_name}`}
                  className="user-avtar-dropdown user-avatar mr-8"
                  style={{
                    bgcolor: el?.profile_color?.background,
                    color: el?.profile_color?.text,
                  }}>
                  {createAvtarName(`${el.first_name} ${el.last_name}`)}
                </Avatar>

                {`${el.first_name} ${el.last_name}`}
              </label>
            </MenuItem>
          );
        })}
    </>
  );
}
