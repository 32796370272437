import React, { useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";

import Boards from "../../components/Filters/Boards";
import SelectDatePicker from "../../components/Filters/SelectDatePicker";
import Epics from "../../components/Filters/Epics";
import FilterUser from "./FilterUsers";
import Download from "./Download";
import TeamFilter from "../../Grouping/components/TeamFilter";
import User from "../../Grouping/components/User";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { addAllBoard, setDateRange } from "../../../../redux/SearchSlice";
import { setAllGroupUser } from "../../../../redux/TaskGroupingSlice";
import { useSearchParams } from "react-router-dom";
import { useTeams } from "../../../../utils/reactQuery/teams";
import TeamUser from "./TeamUser";

function Filters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { teams } = useTeams();
  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };
  const checkedList = useSelector((state) => state.search.boardSearch);
  const myTaskUser = useSelector((state) => state.search.myTaskUser);
  const totalHours = useSelector((state) => state.search.totalWorkHours);
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const Oldboard = searchParams.get("board");
  const Olddate = searchParams.get("date");

  const clearFilters = () => {
    dispatch(addAllBoard([]));
    dispatch(setAllGroupUser([]));
    dispatch(setDateRange(null));
    // removeSearchParam("date");
    // removeSearchParam("board");
    removeSearchParams(["date", "board"]);
  };

  useEffect(() => {
    return () => {
      dispatch(setAllGroupUser([]));
      dispatch(addAllBoard([]));
      dispatch(setDateRange(null));
    };
  }, []);
  console.log("filters", Oldboard, groupingUser, Olddate);
  return (
    <div className="worklog-filter d-flex justify-content-space-between align-items-center mt-4">
      <div className="d-flex">
        {/* <div className="mr-4">
          <p className="total-work">Total Work</p>
          <span className="hours-header">{totalHours} hours</span>
        </div>
        <Download /> */}

        {/* {teams && <TeamFilter isButton={true} team={teams} />}
        <User user={groupingUser.map((item) => item.users).flat()} /> */}
        <TeamUser user={groupingUser.map((item) => item.users).flat()} team={teams}/>
      </div>
      <div className="d-flex align-items-center">
        {(Oldboard || groupingUser.length > 0 || Olddate) && (
          <Button
            size="small"
            endIcon={<ClearIcon />}
            className="mr-2 capitalize"
            onClick={clearFilters}>
            Clear Filter
          </Button>
        )}
        <p className="filter-by mr-1">Filter By:</p>
        <Boards />
        {checkedList && checkedList.length == 1 && <Epics />}
        {/* <FilterUser user={myTaskUser} /> */}
        {/* <Epics/> */}
        <SelectDatePicker />
      </div>
    </div>
  );
}

export default Filters;
